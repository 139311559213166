import React from "react";

export const data_social_media = {
  what_we_do: {
    title: "Jak działamy w social mediach",
    text: [
      <>
        <b>Profile Social Media</b> - Facebook, Instagram, LinkedIn, Pinterest -
        to główne źródła kontaktu Twojej marki z klientem. Wiemy, jak pisać
        treści dopasowane do każdego z nich i jaką kreację graficzną zastosować,
        aby odbiorca zatrzymał się akurat przy Twojej.
      </>,
      <>
        <b>Reklama</b> – zasięg organiczny to nie wszystko. Nawet najlepszy
        content potrzebuje pomocy, aby przebić się przez miliony innych.
        Facebook, Instagram czy LinkedIn - wszystkie te systemy działają na
        swoich warunkach, a my je dobrze znamy.
      </>,
      <>
        <b>Stała współpraca</b> – jeżeli już działamy to na 100%. Widzimy
        perspektywę do współpracy z influencerem? Nie zostawiamy tej wiedzy dla
        siebie! Naszym zadaniem jest zrealizowanie Twojego celu, wykorzystując
        całą naszą wiedzę na temat marketingu internetowego związanego z social
        mediami.
      </>,
      <>
        <b>Szkolenia Social Media</b> – chcesz nabyć nowe umiejętności lub
        podnieść kwalifikacje swojego zespołu? Prowadzimy szkolenia dla tych,
        którzy sami chcą moderować profile social media i prowadzić kampanie
        reklamowe w ich ekosystemach.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Brief",
          "Analiza dotychczasowych działań marketingowych",
          "Analiza rynku i konkurencji",
          "Określenie grupy odbiorców",
          "Koncepcja kompleksowej kampanii reklamowej",
          "Koncepcja komunikacji na profilu marki",
        ],
      },
      {
        title: "Przygotowanie profili",
        list: [
          "Utworzenie i konfiguracja profilu",
          "Zebranie informacji / materiałów",
          "Stworzenie szaty graficznej",
        ],
      },
      {
        title: "Przygotowanie kampanii",
        list: [
          "Konfiguracje narzędzi analitycznych - Pixel Konwersji, Google Tag Manager, Google Analytics",
          "Opracowanie grafik reklamowych",
          "Utworzenie kampanii",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Bieżąca obsługa profilów społecznościowych",
          "Interakcja z fanami marki oraz potencjalnymi klientami",
          "Analiza i optymalizacja dotychczasowych działań",
          "Raportowanie",
        ],
      },
    ],
  },
};
